import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

/**
* Navigation component
*
* The Navigation component takes an array of your Ghost
* navigation property that is fetched from the settings.
* It differentiates between absolute (external) and relative link (internal).
* You can pass it a custom class for your own styles, but it will always fallback
* to a `site-nav-item` class.
*
*/
import ThemeControl from './ThemeControl'

function toggleClick() {
    var x = document.getElementById(`MenuMobile`)
    var y = document.getElementById(`ChevronButton`)
    if (x.className === `nav-menu__mobile`) {
        x.className = `nav-menu__mobile active`
        y.className = `moreless-button icomoon-chevron-thin-up`
    } else {
        x.className = `nav-menu__mobile`
        y.className = `moreless-button icomoon-chevron-thin-down`
    }
}

const Navigation = ({ data, navClass }) => (
    <>
        <nav className="nav-menu__desktop">
            {data.map((navItem, i) => {
                if (navItem.url.match(/^\s?http(s?)/gi)) {
                    return <a className={navClass} href={navItem.url} key={i} rel="noopener noreferrer">{navItem.label}</a>
                } else {
                    return <Link className={navClass } to={navItem.url} key={i}>{navItem.label}</Link>
                }                
            })}
        </nav>

        <nav id="MenuMobile" className="nav-menu__mobile">
			{data.map((navItem, i) => {
				if (navItem.url.match(/^\s?http(s?)/gi)) {
					return <a className={navClass} href={navItem.url} key={i} rel="noopener noreferrer">{navItem.label}</a>
				} else {
					return <Link className={navClass } to={navItem.url} key={i}>{navItem.label}</Link>
				}
			})}
        </nav>

        <nav className="header-controls">
        <ThemeControl />

            {/* <Link className="search-button icomoon-magnifying-glass" to="/search">
                <label className="header-controls__label" htmlFor="search-button">Search</label>
            </Link> */}
            <span id="ChevronButton" role="button"
                className={`moreless-button icomoon-chevron-thin-down`} 
                onClick={toggleClick}
                onKeyDown={toggleClick}
                tabIndex={0}
                ><label className="header-controls__label" htmlFor="moreless-button">Menu</label>
            </span>
        </nav>
    </>
)

Navigation.defaultProps = {
    navClass: `navigation`,
}

Navigation.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    navClass: PropTypes.string,
}

export default Navigation
