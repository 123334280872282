import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'

const PostCard = ({ post }) => {
    const url = `/${post.slug}/`
    const readingTime = readingTimeHelper(post)
    let image = `${post.feature_image}`

    // add a default image if post feature_image is empty
    if (image === "null") {
      image = "/initiatives.png"
    }

    return (
        <Link to={url} className="post-card">

            <section className="post-card__header">
                <figure style={{ backgroundImage: `url(${image})`}} alt={post.title}/>
            </section>

            <section className="post-card__main">
                <h4>{post.title}</h4>
                <p>{post.excerpt}</p>
            </section>
            
            <section className="post-card__footer">
                <p>{post.published_at && <span> {post.published_at_pretty} </span>}</p>
                <p>{readingTime}</p>
            </section> 
        </Link>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        published_at: PropTypes.string,
        published_at_pretty: PropTypes.string,
        featured: PropTypes.bool,
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCard
