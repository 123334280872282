import React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

class ThemeControl extends React.Component {
    render() {
        return (
            <ThemeToggler>
                {/* Stores theme selection to localStorage.theme on click */}
                {({ theme, toggleTheme }) => {
                    if (theme == null) {
                        return null
                    } 
                    return (
                        <div className="footer__controls">
                            <span role="button"
                                className={theme === "dark" ? "themecontrol icomoon-light-up" : "themecontrol icomoon-moon"}
                                onClick={() => toggleTheme(theme === "dark" ? "light" : "dark")}
                                onKeyDown={() => toggleTheme(theme === "dark" ? "light" : "dark")}
                                tabIndex={0}>
                                <label className="header-controls__label" htmlFor="themecontrol">Theme</label>
                            </span>
                        </div>
                    )
                }}
            </ThemeToggler>
        )
    }
}

export default ThemeControl